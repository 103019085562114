<template>
    <div class="scan-result-container">
        <template v-if="showResult">
            <coupon-card-v1 class="card" :card="cardInfo"></coupon-card-v1>
            <div class="user-info">
                <div class="title">您确定要将此礼品卡转赠给这个会员吗？</div>
                <div class="user-render">
                    <div class="avatar">
                        <img :src="queryParams.sendData.sendAvatar"/>
                    </div>
                    <div class="username">
                        {{queryParams.sendData.sendNickName}}
                    </div>
                </div>
            </div>

            <div class="detail-buttons">
                <div class="buttons">
                    <div class="use" @click="sendCoupon">
                        立即转赠
                    </div>
                </div>
            </div>
        </template>
        <loading-v1 ref="loading"></loading-v1>
        <rsck-loading ref="loading2" text="转赠请求提交中"></rsck-loading>
    </div>
</template>

<script>
  import CouponCardV1 from '@/template/coupon-card/coupon-card-v1'
  import LoadingV1 from '@/template/community/loading/loadingV1'
  import RsckLoading from '@/components/common/rsck-loading'

  export default {
    name: 'scan-result',
    components: {RsckLoading, LoadingV1, CouponCardV1},
    mounted () {
      document.title = '礼品卡赠送'
      this.queryParams = this.$route.query
      console.log('赠送的参数', this.queryParams)
      this.getCouponId(this.queryParams.sendData.couponId)
      this.canContinue = true
    },
    data () {
      return {
        queryParams: {},
        cardInfo: {},
        showResult: false,
        loading: false,
        canContinue:false
      }
    },
    methods: {
      getCouponId (coupon_id) {
        this.$refs.loading.show()
        if (!this.loading) {
          this.$api.couponCard.getCouponCardInfo({id: coupon_id}).then(response => {
            this.cardInfo = response.data
            this.cardInfo.card_code = this.queryParams.sendData.orderSn
            this.showResult = true
            setTimeout(() => {
              this.loading = false
              this.$refs.loading.hide()
            }, 500)
          })
        }

      },
      sendCoupon () {
        this.$refs.loading2.show()
        if(this.canContinue) {
          this.canContinue = false

          this.$api.couponCard.doSendCouponCard({
            card_id: this.queryParams.sendData.orderId,
            to_user_id: this.queryParams.sendData.sendUid
          }).then(response => {
            this.$toast.success('转赠成功')
            this.$refs.loading2.hide()

            setTimeout(() => {
              this.$platform.wxsdk.wxRoute({
                type: 'redirectTo',
                url: '/web/couponCard/home',
              })
            }, 500)
          }).catch(error => {
            this.$toast.fail(error.message)
            this.$refs.loading2.hide()
          })

          setTimeout(() => {
            this.canContinue = true
          },500)
        }

      }
    }
  }
</script>

<style scoped lang="scss">
    .scan-result-container {
        width: 100vw;
        min-height: 100vh;
        background: #f6f6f6;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        .user-info {
            display: flex;
            flex-wrap: wrap;
            background: #fff;
            padding: 15px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;

            .title {
                width: 100%;
                color: #333;
                font-size: 14px;
                font-weight: bold;
            }

            .user-render {
                width: 100%;
                display: flex;
                align-items: center;
                margin-top: 15px;

                .avatar {
                    width: 50px;
                    height: 50px;
                    -webkit-border-radius: 50px;
                    -moz-border-radius: 50px;
                    border-radius: 50px;
                    overflow: hidden;
                    margin-right: 20px;

                    img {
                        width: 50px;
                        height: 50px;
                    }
                }

                .username {
                    font-size: 14px;
                    color: #333333;
                }
            }
        }

        .detail-buttons {
            width: 100%;

            .buttons {
                display: flex;
                padding: 15px;

                .use {
                    width: 100%;
                    height: 48px;
                    background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
                    -webkit-border-radius: 6px;
                    -moz-border-radius: 6px;
                    border-radius: 6px;
                    font-size: 14px;
                    color: #FFFFFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
</style>